import {createAction, createSlice} from '@reduxjs/toolkit';
import {AppState} from '../../mappers/app';
import {saveStateToStorage} from '../utils';

const appState: AppState = {
    alert: {type: '', message: ''},
    registered: false,
    showAlert: false,
    error: '',
    loading: false,
    reload: false,
    refreshTokenRequestPending: false,
    config: {tzs: [], currencies: []},
};

export const APP_ACTIONS = {
    GET_CONFIG: 'GET_CONFIG',
};

export const getConfig = createAction(APP_ACTIONS.GET_CONFIG);

const AppSlice = createSlice({
    name: 'app',
    initialState: appState,
    reducers: {
        setError(state, {payload}) {
            state.error = payload;
            // state.showAlert = true;
            // state.alert.type = "error"
            // state.alert.message = payload?.response?.alert
            state.loading = false;
        },
        setRegistrationStatus(state, {payload}) {
            state.registered = payload;
            state.loading = false;
        },
        setRefreshTokenStatus(state, {payload}) {
            state.refreshTokenRequestPending = payload;
        },
        startLoading(state) {
            state.loading = true;
        },
        reload(state, {payload}) {
            state.reload = payload?.reload;
            state.reloadType = payload?.type;
        },
        stopLoading(state) {
            state.loading = false;
        },
        showAlert(state, {payload}) {
            state.showAlert = true;
            state.alert.type = payload.type;
            state.alert.message = payload.message;
        },
        hideAlert(state) {
            state.showAlert = false;
            state.alert.type = '';
            state.alert.message = '';
        },
        setAppConfig(state, {payload}) {
            state.config = payload.data;
            saveStateToStorage('app', state);
        },
    },
});

export const {
    setError,
    startLoading,
    stopLoading,
    hideAlert,
    showAlert,
    setRegistrationStatus,
    setAppConfig,
    setRefreshTokenStatus,
    reload,
} = AppSlice.actions;
export default AppSlice.reducer;
