import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';

import routes from './routes';
import Loader from '../components/common/loader';
import {useSelector} from 'react-redux';
import {RootState} from '../mappers/app';

const RouteHandler = () => {
    const loggedIn = useSelector((state: RootState) =>
        Boolean(state.user?.loggedInUser?.role),
    );
    const routing = useRoutes(routes(loggedIn));
    return <Suspense fallback={<Loader/>}> {routing}</Suspense>;
};

export default RouteHandler;
