import React, {FC} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TopBar from './TopBar';
import {Outlet} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        alignContent: 'flex-start',
        overflow: 'auto',
        paddingTop: 64,
    },
    content: {
        height: '100%',
        width: '100%',
        margin: 'auto',
    },
}));

const FullWidthLayout: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TopBar/>
            <main className={classes.wrapper}>
                <div className={classes.content}>
                    <Outlet/>
                </div>
            </main>
        </div>
    );
};

export default FullWidthLayout;
