import React from 'react';
import './Loader.scss';

const Loader = () => {
    return (
        <section aria-label="loader" className="pan-loader">
            <div className="loader"/>
            <div className="pan-container">
                <div className="pan"/>
                <div className="handle"/>
            </div>
            <div className="shadow"/>
        </section>
    );
};

export default Loader;
