import {createAction, createSlice} from '@reduxjs/toolkit';
import {UserState} from '../../mappers/user';
import {saveStateToStorage} from '../utils';
import {deleteFromSessionStorage} from '../../utils/storage';

const userState: UserState = {
    isAuthenticated: false,
    loggedInUser: undefined,
    error: undefined,
};
export const USER_ACTIONS = {
    GET_CURRENT_USER: 'GET_CURRENT_USER',
};

export const getCurrentUser = createAction(USER_ACTIONS.GET_CURRENT_USER);

const UserSlice = createSlice({
    name: 'user',
    initialState: userState,
    reducers: {
        userLoginSuccess(state, {payload}) {
            state.isAuthenticated = true;
            state.loggedInUser = payload.data;
            saveStateToStorage('user', state);
        },
        userLogoutSuccess(state) {
            state.isAuthenticated = false;
            state.loggedInUser = undefined;
            deleteFromSessionStorage('vm:root');
        },
        userLoginError(state, {payload}) {
            state.isAuthenticated = false;
            state.error = payload;
        },
        updateUser(state, {payload}) {
            state = {...state, loggedInUser: {...state.loggedInUser, ...payload}};
            saveStateToStorage('user', state);
        },
    },
});

export const {
    userLoginSuccess,
    userLoginError,
    updateUser,
    userLogoutSuccess,
} = UserSlice.actions;
export default UserSlice.reducer;
