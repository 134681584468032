import {ApiRequest} from '../mappers/api';
import {hideAlert, showAlert, startLoading, stopLoading,} from '../store/slices/app';
import {getStore} from '../store/store';
import {clearStorage} from '../store/utils';

export interface ApiResponse {
    error?: string;
    alert?: string;
    data?: Record<string, string | number | boolean | Record<any, any>>;
}

export async function request(
    url: string,
    requestOptions: ApiRequest,
): Promise<string | Error | any> {
    const error = {status: 0, response: ''};
    const errors = [];
    const store = getStore();
    if (!url) {
        errors.push('url');
    }

    if (!requestOptions.method) {
        requestOptions.method = 'GET';
    }

    if (!requestOptions.retryCount) {
        requestOptions.retryCount = 1;
    }

    if (!requestOptions.headers) {
        const headers = new Headers();
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        requestOptions.headers = headers;
    }
    if (requestOptions.headers.get('Content-Type') === 'multipart/form-data') {
        requestOptions.headers.delete('Content-Type');
        requestOptions.headers.set('Accept', 'application/json');
    }
    if (
        !requestOptions.payload &&
        requestOptions.method !== 'GET' &&
        requestOptions.method !== 'DELETE'
    ) {
        errors.push('payload');
    }

    if (errors.length) {
        throw Error(`Error! You must pass \`${errors.join('`, `')}\``);
    }

    const isValidation = url.includes('/validate');
    const isLogin = url.includes('/login');
    !isValidation && store.dispatch(startLoading());

    return fetch(url, {
        headers: requestOptions.headers,
        method: requestOptions.method,
        body: requestOptions.payload,
        credentials: 'include',
    })
        .then(async (response) => {
            if (response.status > 299) {
                error.status = response.status;
                error.response = await response.json();
                throw error;
            } else {
                !isValidation && store.dispatch(stopLoading());
                const jsonResponse = await response.json();
                if(jsonResponse?.redirect?.length){
                    window.location.href = jsonResponse.redirect
                    return ;
                }
                if (jsonResponse?.alert && !isValidation) {
                    store.dispatch(
                        showAlert({
                            message: jsonResponse.alert,
                            type: jsonResponse.type || 'success',
                        }),
                    );
                    setTimeout(()=>{store.dispatch(hideAlert())},500);
                }
                return jsonResponse;
            }
        })
        .catch((error) => {
            if (error?.response?.alert && !isValidation) {
                store.dispatch(
                    showAlert({
                        message: error.response.alert,
                        type: error.response.type || 'error',
                    }),
                );
                setTimeout(()=>{store.dispatch(hideAlert())},500);
            }
            !isValidation && store.dispatch(stopLoading());
            if (error.status === 401 && !isLogin) {
                clearStorage();
                setTimeout(() => {
                    window.location.href = '/login';
                }, 1000);
            }
            throw error;
        });
}
