export const saveToLocalStorage = (
    key: string,
    value: Record<string, any>,
): void => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const saveToSessionStorage = (
    key: string,
    value: Record<string, any>,
): void => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key: string): Record<string, unknown> => {
    return JSON.parse(<string>localStorage.getItem(key));
};

export const getFromSessionStorage = (key: string): Record<string, unknown> => {
    return JSON.parse(<string>sessionStorage.getItem(key));
};

export const deleteFromLocalStorage = (key: string): void => {
    localStorage.removeItem(key);
};

export const deleteFromSessionStorage = (key: string): void => {
    sessionStorage.removeItem(key);
};
