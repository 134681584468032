import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import FullWidthLayout from '../components/layouts/full-width';
import NotFound from '../components/common/404';
import VerifyEmail from '../components/common/email';

const DashboardLayout = lazy(() => {
    return import('../components/layouts/dashboard').then((module) => {
        return module;
    });
});

const LandingPage = lazy(() => {
    return import('../components/landing/index').then((module) => {
        return module;
    });
});

const Login = lazy(() => {
    return import('../components/landing/Login').then((module) => {
        return module;
    });
});
const TableLogin = lazy(() => {
    return import('../components/landing/Table').then((module) => {
        return module;
    });
});
const Register = lazy(() =>
    import('../components/landing/Register').then((module) => {
        return module;
    }),
);

const Dashboard = lazy(() =>
    import('../components/dashboard/index').then((module) => {
        return module;
    }),
);

const SaDashboard = lazy(() =>
    import('../components/sa/index').then((module) => {
        return module;
    }),
);
const Branch = lazy(() =>
    import('../components/branch/index').then((module) => {
        return module;
    }),
);
const AddBranch = lazy(() =>
    import('../components/branch/AddBranch').then((module) => {
        return module;
    }),
);

const Table = lazy(() =>
    import('../components/table/index').then((module) => {
        return module;
    }),
);
const AddTable = lazy(() =>
    import('../components/table/AddTable').then((module) => {
        return module;
    }),
);

const TableView = lazy(() =>
    import('../components/table/TableView').then((module) => {
        return module;
    }),
);
const Category = lazy(() =>
    import('../components/category/index').then((module) => {
        return module;
    }),
);
const AddCategory = lazy(() =>
    import('../components/category/AddCategory').then((module) => {
        return module;
    }),
);

const Product = lazy(() =>
    import('../components/products').then((module) => {
        return module;
    }),
);

const Kitchen = lazy(() =>
    import('../components/kitchen').then((module) => {
        return module;
    }),
);

const Settings = lazy(() =>
    import('../components/dashboard/Settings').then((module) => {
        return module;
    }),
);

const PasswordResetRequest = lazy(() =>
    import('../components/common/password/ResetRequest').then((module) => {
        return module;
    }),
);

const PasswordReset = lazy(() =>
    import('../components/common/password/Reset').then((module) => {
        return module;
    }),
);

const Privacy = lazy(() =>
    import('../components/common/privacy').then((module) => {
        return module;
    }),
);

const TOS = lazy(() =>
    import('../components/common/tos').then((module) => {
        return module;
    }),
);

const Scanner = lazy(() =>
    import('../components/common/scanner').then((module) => {
        return module;
    }),
);

const VerifyReRequest = lazy(() =>
    import('../components/common/email/Rerequest').then((module) => {
        return module;
    }),
);

const Reports = lazy(() =>
    import('../components/dashboard/Reports').then((module) => {
        return module;
    }),
);
const routes = (isLoggedIn: boolean | undefined) => [
    {
        path: 'app',
        element: isLoggedIn ? <DashboardLayout/> : <Navigate to="/"/>,
        children: [
            {path: 'dashboard', element: <Dashboard/>},
            {
                path: 'branch',
                children: [
                    {path: '', element: <Branch/>},
                    {path: 'add', element: <AddBranch/>},
                ],
            },
            {
                path: 'table',
                children: [
                    {path: 'add', element: <AddTable/>},
                    {path: '', element: <Table/>},
                    {path: 'view', element: <TableView/>},
                ],
            },
            {
                path: 'category',
                children: [
                    {path: 'add', element: <AddCategory/>},
                    {path: '', element: <Category/>},
                ],
            },
            {
                path: 'product',
                children: [
                    {path: 'add', element: <Product/>},
                    {path: '', element: <Product/>},
                ],
            },
            {
                path: 'kitchen',
                children: [
                    {path: 'add', element: <Kitchen/>},
                    {path: '', element: <Kitchen/>},
                ],
            },
            {path: 'settings', element: <Settings/>},
            {path: 'reports', element: <Reports/>},
            {path: '*', element: <Navigate to="/404"/>},
        ],
    },
    {
        path: '/',
        element: <FullWidthLayout/>,
        children: [
            {path: '', element: <LandingPage/>},
            {path: 'sp', element: <SaDashboard/>},
            {path: 'email', element: <VerifyEmail/>},
            {path: 'email-verify-again', element: <VerifyReRequest/>},
            {path: 'login', element: <Login/>},
            {path: 'register', element: <Register/>},
            {path: 'table', element: <TableLogin/>},
            {path: 'reset', element: <PasswordResetRequest/>},
            {path: 'password-reset', element: <PasswordReset/>},
            {path: 'privacy-policy', element: <Privacy/>},
            {path: 'terms-of-service', element: <TOS/>},
            {path: 'free-qr-scanner', element: <Scanner/>},
            {path: '*', element: <Navigate to="/404"/>},
            {path: '404', element: <NotFound/>},
        ],
    },
];

export default routes;
