import {createAction, createSlice} from '@reduxjs/toolkit';
import {TableState} from '../../mappers/app';
import {Table} from '../../mappers/table';
import {saveStateToStorage} from '../utils';

const tableState: TableState = {
    tables: [],
    orders: [],
    products: [],
    selected: undefined,
    add: false,
    loaded: false,
};

export const TABLE_ACTIONS = {
    ADD_TABLE: 'ADD_TABLE',
    EDIT_TABLE: 'EDIT_TABLE',
    DELETE_TABLE: 'DELETE_TABLE',
    GET_TABLES: 'GET_TABLES',
    GET_PRODUCTS: 'GET_PRODUCTS',
    GET_ORDERS: 'GET_ORDERS',
};

export const addTable = createAction<Table>(TABLE_ACTIONS.ADD_TABLE);
export const editTable = createAction<Table>(TABLE_ACTIONS.EDIT_TABLE);
export const deleteTable = createAction<string | undefined>(
    TABLE_ACTIONS.DELETE_TABLE,
);
export const getTables = createAction(TABLE_ACTIONS.GET_TABLES);
const TableSlice = createSlice({
    name: 'table',
    initialState: tableState,
    reducers: {
        setTables(state, {payload}) {
            state.tables = payload.data;
            state.loaded = true;
        },
        setTableInState(state, {payload}) {
            state.selected = payload;
            saveStateToStorage('table', state);
        },
        initiateTableAdd(state, {payload}) {
            state.add = payload;
        },
    },
});

export const {setTables, setTableInState, initiateTableAdd} =
    TableSlice.actions;
export default TableSlice.reducer;
