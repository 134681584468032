import React, {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';
import Logo from '../../common/logo';
import Button from '@mui/material/Button';
import createStyles from '@mui/styles/createStyles';
import theme from '../../../theme';

const useStyles = makeStyles((rootTheme: typeof theme) => {
    return createStyles({
        toolbar: {
            height: 64,
        },
        menu: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            background: 'white',
            width: '100%',
            paddingLeft: '1rem',
            paddingLRight: '1rem',
            boxShadow: '1px 3px 5px 0px rgba(50, 50, 50, 0.1)',
        },
        signInButton: {
            color: 'white',
            background: '#18A558',
            margin: '.5rem',
            fontSize: '.6rem',
            '&:hover': {
                background: '#282120',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
            },
        },
        signUpButton: {
            color: 'white',
            background: '#21B6A8',
            fontSize: '.6rem',
            '&:hover': {
                background: '#282120',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
            },
        },
    });
});

const TopBar: FC = () => {
    const classes = useStyles();

    return (
        <AppBar
            data-testid="app-bar"
            aria-label="menu bar"
            className={classes.menu}
            elevation={0}
        >
            <Logo/>
            <Toolbar data-testid="tool-bar" className={classes.toolbar}>
                <Button
                    component={RouterLink}
                    className={classes.signInButton}
                    aria-label="login link"
                    to={'/login'}
                    tabIndex={2}
                    data-testid="sign-in"
                >
                    Sign in
                </Button>
                <Button
                    component={RouterLink}
                    aria-label="register link"
                    to={'/register'}
                    className={classes.signUpButton}
                    tabIndex={3}
                    data-testid="sign-up"
                >
                    Sign up
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
