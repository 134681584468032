import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import logo from '../../../assets/images/full-logo.webp';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
}));

const Logo = () => {
    const classes = useStyles();

    return (
        <Link
            to={window.location?.href?.includes('/app/') ? '/app/dashboard' : '/'}
            tabIndex={1}
            aria-label={'landing page link'}
        >
            <img
                alt="virtual-menus-logo"
                width={150}
                height={60}
                className={classes.root}
                src={logo}
            />
        </Link>
    );
};

export default Logo;
