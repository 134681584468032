import shadows from './shadows';
import typography from './typography';
import {createTheme} from '@mui/material/styles';
import {colors} from '@mui/material';

const theme = createTheme({
    palette: {
        background: {
            dark: '#F4F6F8',
            default: colors.common.white,
            paper: colors.common.white,
        },
        primary: {
            main: '#6464CA',
        },
        secondary: {
            main: '#EF476F',
        },
        text: {
            primary: '#011627',
            secondary: '#808c90',
        },
    },
    typography: typography,
    shadows: shadows,
});

export default theme;
