import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ToastProps} from '../mappers/app';
import {ReactText} from 'react';

export default function ToastHandler(
    props: ToastProps,
    type?: string,
    id?:string,
): ReactText | null {
    const toastOptions: ToastProps = {
        message: props.message || '',
        position: props.position || 'bottom-center',
        autoClose: props.autoClose || 2000,
        hideProgressBar: props.hideProgressBar || false,
        closeOnClick: props.closeOnClick || true,
        pauseOnHover: props.pauseOnHover || true,
        draggable: props.draggable || true,
        progress: undefined,
        toastId: props.toastId,
    };
    toast.configure({containerId: id || '123'});
    switch (type) {
        case 'info':
            return toast.info(props.message, toastOptions);
        case 'error':
            return toast.error(props.message, toastOptions);
        case 'warn':
            return toast.warn(props.message, toastOptions);
        case 'success':
            return toast.success(props.message, toastOptions);
        default:
            return null;
    }
}
