import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {Provider} from 'react-redux';
import {getStore} from './store/store';
import {BrowserRouter} from 'react-router-dom';
import ThemeProvider from '@mui/styles/ThemeProvider';
import theme from './theme';
import GlobalStyles from './theme/GlobalStyles';
import {StyledEngineProvider} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import {ToastContainer} from "react-toastify";

const store = getStore();
// Sentry.init({
//   dsn: 'https://bac1db8cdef349ca855ea7fe4691ed89@o958544.ingest.sentry.io/5907231',
//   integrations: [new Integrations.BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <StyledEngineProvider injectFirst>
                    <GlobalStyles/>
                    <ToastContainer />
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <App/>
                    </ThemeProvider>
                </StyledEngineProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
