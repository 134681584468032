import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import createStyles from '@mui/styles/createStyles';
import theme from '../../../theme';
import Grid from '@mui/material/Grid/Grid';
import {Animate} from 'react-simple-animate';
import {Card, CardContent, CircularProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import {request} from '../../../utils/apiClient';
import {getConfig} from '../../../utils/config';
import ToastHandler from '../../../utils/toast';
import {useSelector} from 'react-redux';
import {RootState} from '../../../mappers/app';
import {Done, Error} from '@mui/icons-material';

const useStyles = makeStyles(({spacing}: typeof theme) => {
    return createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
        },
        errorIcon: {
            fill: 'red',
            fontSize: '5rem',
        },
        successIcon: {
            fill: 'green',
            fontSize: '5rem',
        },
    });
});

const VerifyEmail = () => {
    const classes = useStyles();
    const {search} = useLocation();
    const appState = useSelector((state: RootState) => state?.app);

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        setLoading(true);
        request(
            `${getConfig('API_URL')}email/verify?code=${queryParams?.get('code')}`,
            {method: 'GET'},
        )
            .then((response) => {
                setLoading(false);
                setStatus(response?.alert);
            })
            .catch(() => {
                setLoading(false);
                setStatus('error');
            });
    }, [search]);

    useEffect(() => {
        if (appState?.showAlert) {
            ToastHandler(
                {message: appState.alert.message},
                appState.alert.type || 'success',
            );

            if (appState.alert?.type === 'success') {
                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            }
        }
    }, [appState?.showAlert]);
    return (
        <Box className={classes.root}>
            <Animate
                play={true}
                start={{opacity: 0, filter: 'blur(10px)'}}
                end={{opacity: 1, filter: 'blur(0px)'}}
            >
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            {loading ? (
                                <Box>
                                    <Typography
                                        sx={{fontSize: 14}}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        Verifying your email
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Please wait
                                    </Typography>
                                    <CircularProgress/>
                                </Box>
                            ) : (
                                <Box>
                                    {status !== 'error' ? (
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            alignItems={'center'}
                                        >
                                            <Done className={classes.successIcon}/>
                                            <Typography variant={'h5'}>{status}</Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            alignItems={'center'}
                                        >
                                            <Error className={classes.errorIcon}/>

                                            <Typography variant={'h5'}>
                                                Oh!, Error occurred please contact support@scannbuy.net
                                                with your email address
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Animate>
        </Box>
    );
};

export default VerifyEmail;
