import {createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {applyMiddleware, combineReducers, compose, Reducer, ReducersMapObject,} from '@reduxjs/toolkit';
import {createSagaInjector} from './sagas/InjectSaga';
import appReducer from './slices/app';
import userReducer from './slices/user';
import tableReducer from './slices/table';
import logger from 'redux-logger';
import {Saga} from '@redux-saga/types';
import {loadStateFromStorage} from './utils';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
type StoreInjectable = ReturnType<typeof createStore> & {
    injectReducers(reducers: Record<string, Reducer>): void;
    injectSaga(key: string, saga: Saga): void;
};

let store: StoreInjectable;

let initialReducers = {};
export const configStore = (): StoreInjectable => {
    const middlewares = [sagaMiddleware, logger];
    if (process.env.NODE_ENV === 'production') middlewares.splice(1, 1);
    const middleWareEnhancer = applyMiddleware(...middlewares);
    initialReducers = {app: appReducer, user: userReducer, table: tableReducer};
    const store = createStore(
        getCombinedReducers(),
        loadStateFromStorage() || {},
        composeEnhancers(middleWareEnhancer),
    ) as StoreInjectable;
    store['injectReducers'] = function (reducers: any) {
        this.replaceReducer(getCombinedReducers(reducers));
    };
    store.injectSaga = createSagaInjector(sagaMiddleware.run);
    return store;
};

const getCombinedReducers = (reducers?: ReducersMapObject): Reducer => {
    return combineReducers({...initialReducers, ...reducers});
};

export const getStore = (): StoreInjectable => {
    if (!store) {
        store = configStore();
    }
    return store;
};
