import {Saga, Task} from '@redux-saga/types';

export function createSagaInjector(
    runSaga: <S extends Saga>(saga: S, ...args: Parameters<S>) => Task,
) {
    const injectedSagas = new Map();

    const isInjected = (key: string) => injectedSagas.has(key);

    const injectSaga = (key: string, saga: Saga) => {
        if (isInjected(key)) return;
        const task = runSaga(saga);

        injectedSagas.set(key, task);
    };

    return injectSaga;
}
